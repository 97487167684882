const getAbbreviationParams = num => {
  let baseNumber = 1;
  let suffix = "";

  if (num > 999_999_999) {
    baseNumber = 1_000_000_000;
    suffix = "b";
  } else if (num > 999_999) {
    baseNumber = 1_000_000;
    suffix = "m";
  } else if (num > 999) {
    baseNumber = 1000;
    suffix = "k";
  }

  return { baseNumber, suffix };
};

export default num => {
  const { baseNumber, suffix } = getAbbreviationParams(num);
  return `${Math.sign(num) * (Math.abs(num) / baseNumber).toFixed(1)}${suffix}`;
};
