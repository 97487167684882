import styled from "styled-components";
import PropTypes from "prop-types";
import React from "react";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { P1 } from "app/components/Typography";
import abbreviateCount from "helpers/abbreviateCount";

const InfoWrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.ltsm} {
    flex-direction: column-reverse;
  }
`;

const Bar = styled(Div)`
  background: ${({ theme }) => theme.colors.monochrome[1]}
  border-radius: 4px;
  width: 2px;
  height: 16px;
`;

const ProfileMetrics = ({ postsCount = 0, likesCount = 0 }) => (
  <Flex alignItems={{ _: "flex-end", sm: "center" }}>
    <InfoWrapper mr={3}>
      <P1 textTransform={{ _: "uppercase", sm: "none" }} lineHeight="110%">
        Posts
      </P1>
      <P1 fontWeight="bold">{postsCount}</P1>
    </InfoWrapper>
    <Bar />
    <InfoWrapper ml={3}>
      <P1 textTransform={{ _: "uppercase", sm: "none" }} lineHeight="110%">
        Likes
      </P1>
      <P1 fontWeight="bold">{abbreviateCount(likesCount)}</P1>
    </InfoWrapper>
  </Flex>
);

ProfileMetrics.propTypes = {
  postsCount: PropTypes.number,
  likesCount: PropTypes.number,
};

export default ProfileMetrics;
